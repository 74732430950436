<template>
  <div class="signSuccess-wrap">
    <div class="img-wrap">
      <SvgIcon type="sign_success" class="icon" />
    </div>
    <div class="title">签署完毕</div>
    <section class="des">
      <p>您可以在pc端登录账号查看文件进度</p>
      <p>
        方式一：复制网址到电脑端操作 {{ systemInfo.hostPC }}
        <span class="copy-button" @click="copy">复制</span>
      </p>
      <p>
        方式二：电脑端浏览器搜索【{{ systemInfo.company }}】登录后即可查看处理
      </p>
    </section>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name: 'SignSuccess',
    data() {
      return {
        url: 'https://saas.ecloudsign.com',
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    methods: {
      copy() {
        const oInput = document.createElement('input')
        oInput.value = this.systemInfo.hostPC
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        this.$toast('复制成功!')
      },
    },
  }
</script>
<style lang="less" scoped>
  .signSuccess-wrap {
    .img-wrap {
      margin: 90px auto 16px;
      display: flex;
      justify-content: center;
      // width: 120px;
      // height: 120px;
      .icon {
        font-size: 120px;
      }
    }
    .title {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(17, 26, 52, 1);
      line-height: 24px;
      text-align: center;
    }
    .des {
      width: 327px;
      margin: 28px auto 0;
      font-size: 14px;
      font-weight: 400;
      color: rgba(103, 114, 131, 1);
      line-height: 24px;
      margin-bottom: 64px;
      .copy-button {
        margin-left: 4px;
        color: @BLUE;
        font-size: 12px;
      }
    }
    .btn {
      width: 327px;
      height: 50px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 25px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }
  }
</style>
